import { Address } from '@rootTypes';

export type ApiRouteIssueEntity = {
  batchId?: string;
  entityType: RouteIssueEntityType;
  entityId: string;
  districtProgramId: string;
  entity: {
    title: string;
    description: View; //Where `Resolve` button is
    views: View[];
  };
  entityProjection: StudentProjection | CampusProjection | RouteIssueProjection;
  issues: ApiRouteIssue[];
  affectedRouteIds: string[];
};

export enum RouteIssueEntityType {
  CAMPUS = 'CAMPUS',
  STUDENT = 'STUDENT',
  BUS_STOP = 'BUS_STOP',
  ROUTE = 'ROUTE',
}

export enum RouteIssueType {
  STUDENT_CAMPUS_ENROLLMENT = 'STUDENT_CAMPUS_ENROLLMENT',
  STUDENT_ADDRESS = 'STUDENT_ADDRESS',
  STUDENT_TRANSPORTATION_NON_ELIGIBLE = 'STUDENT_TRANSPORTATION_NON_ELIGIBLE',
  STUDENT_UNASSIGNED = 'STUDENT_UNASSIGNED',
  STUDENT_DUPLICATED_ROUTE = 'STUDENT_DUPLICATED_ROUTE',
  STUDENT_MISSING_ROUTE = 'STUDENT_MISSING_ROUTE',
  CAMPUS_BELL_TIME = 'CAMPUS_BELL_TIME',
  CAMPUS_BELL_TIME_EXCEPTION = 'CAMPUS_BELL_TIME_EXCEPTION',
  CAMPUS_CALENDAR = 'CAMPUS_CALENDAR',
  CAMPUS_STOP_ADDRESS = 'CAMPUS_STOP_ADDRESS',
  ROUTE_NAVIGATION_VIOLATION = 'ROUTE_NAVIGATION_VIOLATION',
}

export type ApiRouteIssue = {
  title: string;
  type: RouteIssueType;
  groups: Group[];
  affectedRouteIds: string[];
};

export type Group = {
  title: string; //routeDirection
  views: View[];
};

export type View = {
  header?: {
    text: string;
    isBold: boolean;
    isIssue: boolean;
  };
  body?: {
    lines: BodyLine[];
    contentListType: ContentListType;
    collapsible?: {
      collapsedLabel: string;
      expandedLabel: string;
    };
  };
  subView?: {
    views: View[];
    collapsible?: {
      collapsedLabel: string;
      expandedLabel: string;
    };
  };
};

export type BodyLine = { text: string; isIssue: boolean };

export enum ContentListType {
  BULLET = 'BULLET',
  NUMBER = 'NUMBER',
  NONE = 'NONE',
}

export type StudentProjection = {
  fullName: string;
  homeAddresses: Address[];
  campusAddresses: Address[];
  primaryCampusId?: string;
};

export type CampusProjection = {
  name: string;
  addresses: Address[];
};

export type RouteIssueProjection = {};
