import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, OnDestroy, Output } from '@angular/core';
import { getAddressFromPlace } from '@rootTypes/utils';
import { Address, MapLocation } from '@rootTypes';
import { GeocoderService } from '../../core/services';

const coordinateRegex = new RegExp(
  '^[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?),?[,\\s]{1}[-+]?(180(\\.0+)?|((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?)$',
);

@Directive({
  selector: '[wpGoogleAddressAutocomplete]',
})
export class GoogleAddressAutocompleteDirective implements AfterViewInit, OnDestroy {
  @Output() public addressSelected = new EventEmitter<Address>();

  private mapsEventListener: google.maps.MapsEventListener;
  constructor(
    private el: ElementRef,
    private geocoderService: GeocoderService,
  ) {}

  @HostListener('input', ['$event']) private onInput(event: Event) {
    const value: string = (event.target as any).value;
    const coords = this.getCoordsFromInput(value);
    if (coords) {
      this.handleCoordinatesInput(coords);
    }
  }

  ngOnDestroy(): void {
    if (this.mapsEventListener) {
      google.maps.event.removeListener(this.mapsEventListener);
    }
  }

  ngAfterViewInit(): void {
    this.initGoogleAutocomplete();
  }

  private handleCoordinatesInput(location: MapLocation): void {
    this.geocoderService.getAddressFromLocation(new google.maps.LatLng(location.lat, location.lng)).then((address) => {
      if (address) {
        this.addressSelected.emit({ ...address });
      }
    });
  }

  private getCoordsFromInput(source: string): MapLocation | null {
    if (!source) {
      return null;
    }
    if (!coordinateRegex.test(source)) {
      return null;
    }
    const [latStr, lonStr] = source.split(', ');
    const lat = parseFloat(latStr);
    const lng = parseFloat(lonStr);
    if (lat < -90 || lat > 90 || lng < -180 || lng > 180) {
      return null;
    }
    return {
      lat,
      lng,
    };
  }

  private initGoogleAutocomplete(): void {
    try {
      const inputElement = this.el.nativeElement as HTMLInputElement;
      const options = {
        bounds: new google.maps.LatLngBounds(
          new google.maps.LatLng(37.06895739769219, -122.25214251093752),
          new google.maps.LatLng(37.85813042281522, -121.99396380000002),
        ),
        fields: ['address_components', 'geometry', 'formatted_address', 'name'],
        strictBounds: false,
      } as google.maps.places.AutocompleteOptions;
      const autocomplete = new google.maps.places.Autocomplete(inputElement, options);
      const emitAddress = () => {
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();
        const placeWithAddressComponents = place?.address_components ? place : null;
        if (!placeWithAddressComponents) {
          console.error("Can't find address_components on selected address");
        }

        const address = placeWithAddressComponents ? getAddressFromPlace(placeWithAddressComponents) : null;
        this.addressSelected.emit(address);
      };
      this.mapsEventListener = autocomplete.addListener('place_changed', emitAddress.bind(this));
    } catch (err) {
      console.error(err);
    }
  }
}
