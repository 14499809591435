<div class="wp-dp-month">
  <div class="month-label" (click)="onMonthHeaderClick()">
    {{ month.label }}
    <span class="arrow-down"></span>
  </div>
  <div class="vertical-line"></div>
  <div class="weekdays">
    <div class="weekday" *ngFor="let weekday of weekdays$ | async">{{ weekday.label }}</div>
  </div>
  <div *ngIf="isInitialized" class="days">
    <div class="day" *ngFor="let day of days$ | async; trackBy: trackByFn">
      <wp-dp-day [borderSelectionStyle]="borderSelectionStyle" [day]="day" [readonly]="readonly"></wp-dp-day>
    </div>
  </div>

  <div *ngIf="!isInitialized" class="days">
    <div *ngFor="let day of days$ | async; trackBy: trackByFn" class="day">
      <wp-dp-day-placeholder [day]="day"></wp-dp-day-placeholder>
    </div>
  </div>
</div>
