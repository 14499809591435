import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  TrackByFunction,
  Output,
  EventEmitter,
} from '@angular/core';
import { DateRangePickerService } from '../date-range-picker.service';
import { Observable } from 'rxjs';
import * as fromTypes from '../types';
import { map, skip } from 'rxjs/operators';
import { DayConfigs } from '../types';

@Component({
  selector: 'wp-multi-date-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [DateRangePickerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangePickerComponent implements OnInit, OnChanges {
  /**
   * YYYYMMDD[]
   */
  @Input() public selected: fromTypes.YYYYMMDDString[];
  @Input() public disableDatesAfter: fromTypes.YYYYMMDDString;
  @Input() public disableDatesBefore: fromTypes.YYYYMMDDString;

  /**
   * Allow to select only one date
   */
  @Input() public isSingleSelect: boolean;
  /**
   * Select in week spans, 0 - disabled
   */
  @Input() public weekSelectCount = 0;
  /**
   * @deprecated
   * Disable whole week if the disabled range falls in the mid-week (only when isWeekSelect = true)
   */
  @Input() public isStrictDisableWeek: boolean;
  /**
   * If true, selected date gets unselected on click
   */
  @Input() public isDeselectOnSelectedClick = true;
  /**
   * Highlight days with colors, tooltips
   */
  @Input() public dayConfigs: DayConfigs;
  /**
   * Number of months displayed
   */
  @Input() public monthCount!: number;
  /**
   * Disable selecting dates
   */
  @Input() public readonly = false;

  @Input() public borderSelectionStyle = false;

  /**
   * Start week from monday
   */
  @Input() public isStartWeekFromMonday = true;

  @Output() public selectedChanged: EventEmitter<fromTypes.YYYYMMDDString[]> = new EventEmitter<
    fromTypes.YYYYMMDDString[]
  >();
  @Output() public focusInEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public focusOutEvent: EventEmitter<void> = new EventEmitter<void>();

  public readonly$: Observable<boolean>;
  public months$!: Observable<fromTypes.RangePickerMonth[]>;
  public decades$!: Observable<fromTypes.RangePickerDecade[]>;
  public currentView$: Observable<fromTypes.ViewType>;
  public isSingleMonth$: Observable<boolean>;
  public viewTypes = fromTypes.ViewType;
  public trackByFn: TrackByFunction<fromTypes.RangePickerMonth> = (index: number, item: fromTypes.RangePickerMonth) => {
    return item.label;
  };

  constructor(
    private store: DateRangePickerService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.dayConfigs) {
      this.store.setDayConfigs(changes.dayConfigs.currentValue || {});
    }
    if (changes && changes.readonly) {
      this.store.setReadonly(changes.readonly.currentValue || false);
    }
    if (changes && changes.isSingleSelect) {
      const val = changes.isSingleSelect.currentValue || false;
      this.store.setIsSingleSelect(val);
    }
    if (changes && changes.weekSelectCount) {
      const val = changes.weekSelectCount.currentValue || 0;
      this.store.setWeekSelectCount(val);
    }
    if (changes && changes.isStrictDisableWeek) {
      const val = changes.isStrictDisableWeek.currentValue || false;
      this.store.setIsStrictDisableWeek(val);
    }
    if (changes && changes.disableDatesAfter) {
      const val = changes.disableDatesAfter.currentValue || null;
      this.store.setDisableAfterDate(val);
    }
    if (changes && changes.disableDatesBefore) {
      const val = changes.disableDatesBefore.currentValue || null;
      this.store.setDisableBeforeDate(val);
    }
    if (changes && changes.isDeselectOnSelectedClick) {
      const val = changes.isDeselectOnSelectedClick.currentValue ?? true;
      this.store.setIsDeselectOnClick(val);
    }
    if (changes && changes.isStartWeekFromMonday) {
      const val = changes.isStartWeekFromMonday.currentValue || false;
      this.store.setIsStartWeekFromMonday(val);
      const selected = this.selected || [];
      this.store.setSelectedDates(selected);
    }
    if (changes && changes.selected) {
      const val = changes.selected.currentValue || ([] as fromTypes.YYYYMMDDString[]);
      this.store.setSelectedDates(val);
    }
  }

  ngOnInit(): void {
    this.init();
  }

  public onForwardClick(): void {
    this.store.forward();
  }

  public onBackwardClick(): void {
    this.store.backward();
  }

  public onMouseDown(): void {
    this.store.setIsMouseDown(true);
  }

  public onMouseUp(): void {
    this.store.setIsMouseDown(false);
  }

  private init(): void {
    if (this.monthCount) {
      this.store.setMonthView(this.monthCount);
    }
    if (this.selected && this.selected[0]) {
      this.store.setCurrentMonth(this.selected[0]);
    }
    this.readonly$ = this.store.isReadOnly();
    this.currentView$ = this.store.getCurrentViewType$();
    this.months$ = this.store.months$();
    this.isSingleMonth$ = this.store.getConfigs().pipe(map((config) => config.numMonthsDisplayed <= 1));
    this.decades$ = this.store.decades$();
    this.store
      .selectedChangedByUserAction$()
      .pipe(skip(1))
      .subscribe((val) => {
        this.selectedChanged.emit(val || []);
      });
    this.cd.detectChanges();
  }
}
