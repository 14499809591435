import { Address, MapLocation, MapMarker, WpError } from '@rootTypes';
import { Injectable } from '@angular/core';
import { ApiService } from '../../../api/api.service';
import { distinctUntilChanged, map, shareReplay, startWith, tap } from 'rxjs/operators';
import { SmartSelect } from './smart-select';
import { SmartInputAddress } from './smart-input-address';
import { SmartInputAddressMap } from './smart-input-address-map';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectOption } from '../../form-controls';
import { ApiAddress } from '../../../api/endpoints/common';
import { getAbsoluteIconPath, iconPaths } from '@rootTypes/utils';
import { BehaviorSubject, Connectable, firstValueFrom, Observable, Subscription, combineLatest } from 'rxjs';
import { AddressLocationType, ExactLocationValue } from '@apiEntities';
import { BusAddress } from '@apiEntities/district/bus-address';
import { SmartInputModel } from './smart-input-model';
import { GetBusAddressesRequest } from '../../../api/endpoints/get-bus-addresses';

export const defaultLocationType: AddressLocationType = 'home';

export type AddressWithExactLocationValue = {
  busAddressId: string | undefined;
  locationType?: AddressLocationType;
  address?: Address;
  exactLocation?: ExactLocationValue | null;
};

// use it to incorporate this form into other forms
export type RawAddressWithExactLocationFormValue = {
  locationType: { id: string };
  busAddress: SelectOption<string, BusAddress>;
  homeAddress: Address;
  exactHomeAddressLocation: ExactLocationValue;
};

export interface SmartAddressWithExactLocationConfig {
  value?: AddressWithExactLocationValue;
  required?: boolean;
  disabled?: boolean;
  districtId: string;
  districtProgramId: string;
  campusId: string;
  locationType?: {
    label?: string;
  };
  exactAddressSelect?: {
    mapWidthPx?: number;
    mapHeightPx?: number;
    initialZoom?: number;
  };
  closestBusStops?: {
    searchNearAddress?: Address;
  };
}

export interface SmartAddressWithExactLocationDataLoader {
  getBusAddresses: (districtId: string, districtProgramId: string) => Promise<BusAddress[]>;
  getAddressByLocation: (
    districtId: string,
    address: Address,
    location: { lat: number; lng: number },
  ) => Promise<ExactLocationValue>;
  getClosestBusStops: (
    districtId: string,
    districtProgramId: string,
    location: MapLocation,
    campusId: string,
  ) => Promise<BusAddress[]>;
}

export class SmartAddressWithExactLocation implements SmartInputModel {
  public selectedLocationType$: Observable<'home' | 'bus'>;
  public isBusAddressesLoading$ = new BehaviorSubject<boolean>(false);
  public busAddressOptions$ = new BehaviorSubject<SelectOption[]>([]);
  public isBusAddressesLoaded$ = new BehaviorSubject<boolean>(false);
  public busAddressLoadError$ = new BehaviorSubject<WpError>(null);
  public isMapLoading$: Observable<boolean>;
  public closestBusStopOptions$ = new BehaviorSubject<SelectOption[]>([]);
  public isClosestBusStopOptionsLoading$ = new BehaviorSubject<boolean>(false);
  public closestBusStopOptionsLoadError$ = new BehaviorSubject<WpError>(null);

  public isExactLocationValueLoading$ = new BehaviorSubject<boolean>(false);
  public isExactLocationValueLoadError$ = new BehaviorSubject<WpError>(null);

  public locationTypeSelect: SmartSelect;
  // FormControl with select option
  public busAddressSelect: UntypedFormControl;
  public homeAddressSelect: SmartInputAddress;
  public exactHomeAddressSelect: SmartInputAddressMap;
  public searchBusStopsNearAddress: SmartInputAddress;
  // FormControl of type ExactLocationValue
  public exactHomeAddressValue: UntypedFormControl;
  public formGroup: UntypedFormGroup;

  private dataLoader: SmartAddressWithExactLocationDataLoader;
  public markerIconPath = iconPaths.MAP_PIN_GREEN_WHITE_BG;

  private subscriptions = new Subscription();
  private isMapLoading$$ = new BehaviorSubject<boolean>(false);
  private shoudLoadPinValueFlag = true;
  private shouldLoadMapForHomeAddress = true;
  private _hasChanges = false;
  private isExactLocation$$: Connectable<boolean>;

  constructor(public config: SmartAddressWithExactLocationConfig) {
    console.log('smartAddressWithExactLocationConfig campusId', config?.campusId);
    this.isMapLoading$ = this.isMapLoading$$.asObservable();
    this.initFormControls();
    this.initFormControlListeners();
  }

  public async searchClosestBusStopOptionsForLocation(loc: MapLocation): Promise<void> {
    if (!this.dataLoader) {
      return;
    }
    this.isClosestBusStopOptionsLoading$.next(true);
    try {
      const busAddresses = await this.dataLoader.getClosestBusStops(
        this.districtId,
        this.districtProgramId,
        loc,
        this.config.campusId,
      );
      const options = busAddresses.map((addr) => {
        return {
          value: addr.busAddressId,
          displayLabel: addr.address.formatted_address,
          meta: addr,
        } as SelectOption;
      });
      this.closestBusStopOptions$.next(options);
    } catch (err) {
      console.log(err);
      this.closestBusStopOptionsLoadError$.next({
        text: 'Failed to load closest bus stops',
        originalError: err,
      });
    } finally {
      this.isClosestBusStopOptionsLoading$.next(false);
    }
  }

  public get districtId(): string {
    return this.config.districtId;
  }

  public get districtProgramId(): string {
    return this.config.districtProgramId;
  }

  public setDisabled(disabled: boolean): void {
    this.locationTypeSelect.setDisabled(disabled);
    this.homeAddressSelect.control.disable();
    if (disabled) {
      this.busAddressSelect.disable();
    } else {
      this.busAddressSelect.enable();
    }
  }

  public isExactLocation$(): Observable<boolean> {
    const isSelectedHomeAddress$ = this.homeAddressSelect.getValueChanges().pipe(
      startWith(this.homeAddressSelect.getValue()),
      map((v) => !!v),
      shareReplay({ refCount: false, bufferSize: 1 }),
      tap((v) => console.log('is selected', v)),
    );

    return combineLatest([this.selectedLocationType$, isSelectedHomeAddress$]).pipe(
      map(([locType, isSelectedHomeAddress]) => {
        return locType === 'home' && isSelectedHomeAddress;
      }),
      shareReplay({ refCount: false, bufferSize: 1 }),
      tap((v) => console.log('isExactLocation$', v)),
    );
  }

  public getSelectedHomeAddress$(): Observable<Address> {
    return this.homeAddressSelect.control.valueChanges.pipe(startWith(this.homeAddressSelect.control.value));
  }

  public getValueChanges(): Observable<AddressWithExactLocationValue> {
    return this.formGroup.valueChanges.pipe(map(() => this.getValue()));
  }

  public getValue(): AddressWithExactLocationValue {
    if (!this.formGroup) {
      return undefined;
    }
    const formVal: RawAddressWithExactLocationFormValue = this.formGroup.getRawValue();
    const locationType = formVal.locationType?.id as AddressLocationType;
    const address = locationType === 'home' ? formVal.homeAddress : formVal.busAddress?.meta?.address;
    const result: AddressWithExactLocationValue = {
      busAddressId: formVal.busAddress?.value,
      locationType,
      address,
    };
    if (locationType === 'home') {
      result.exactLocation = formVal.exactHomeAddressLocation;
    }
    return result;
  }

  public isValid(): boolean {
    return this.formGroup.valid;
  }

  public setValue(val: AddressWithExactLocationValue): void {
    // address and exact location
    const locationType = val?.locationType ? { id: val.locationType } : { id: undefined };
    const exactLocation = val?.exactLocation || null;
    const busAddressId = val?.busAddressId;
    const busAddress =
      busAddressId && val?.locationType === 'bus' && val?.address
        ? ({
            value: busAddressId,
            displayLabel: val.address.formatted_address,
            meta: { busAddressId, address: val.address },
          } as SelectOption<string, BusAddress>)
        : { value: undefined };
    const homeAddress = val?.locationType === 'home' && val?.address ? val.address : null;
    this.formGroup.setValue({
      locationType,
      busAddress,
      homeAddress,
      exactHomeAddressLocation: exactLocation,
    } as RawAddressWithExactLocationFormValue);
  }

  public exactHomeLocationLoadedValue$(): Observable<ExactLocationValue> {
    return this.exactHomeAddressValue.valueChanges.pipe(
      startWith(this.exactHomeAddressValue.value),
      shareReplay({ refCount: false, bufferSize: 1 }),
    );
  }

  public setDataLoader(dataLoader: SmartAddressWithExactLocationDataLoader): void {
    this.dataLoader = dataLoader;
    if (this.locationTypeSelect && this.locationTypeSelect.control?.value?.id === 'bus') {
      this.loadBusAddressOptions();
    }
  }

  public removeExactLocation(): void {
    this.shoudLoadPinValueFlag = false;
    this.isMapLoading$$.next(true);
    this.exactHomeAddressSelect.setValue(this.homeAddressSelect.getValue());
    this.exactHomeAddressValue.setValue(null);
    setTimeout(() => {
      this.isMapLoading$$.next(false);
      this.shoudLoadPinValueFlag = true;
    }, 300);
  }

  public showErrorIfAny(): void {
    this.shoudLoadPinValueFlag = false;
    this.shouldLoadMapForHomeAddress = false;
    this.locationTypeSelect.showErrorIfAny();
    this.homeAddressSelect.showErrorIfAny();
    this.busAddressSelect.markAsTouched();
    this.busAddressSelect.updateValueAndValidity();
    this.exactHomeAddressValue.updateValueAndValidity();
    setTimeout(() => {
      this.shoudLoadPinValueFlag = true;
      this.shouldLoadMapForHomeAddress = true;
    }, 200);
  }

  public isFormValid$(): Observable<boolean> {
    return this.formGroup.statusChanges.pipe(map((status) => status === 'VALID'));
  }

  private initFormControls(): void {
    this.locationTypeSelect = new SmartSelect({
      label: this.config.locationType?.label || 'Stop type',
      disabled: this.config.disabled,
      lookup: {
        fixed: [
          {
            displayLabel: 'Address',
            value: 'home',
          },
          {
            displayLabel: 'Bus stop',
            value: 'bus',
          },
        ] as SelectOption<AddressLocationType>[],
      },
      value: this.config?.value?.locationType ? { id: this.config.value.locationType } : { id: undefined },
      required: this.config.required,
    });
    this.selectedLocationType$ = this.locationTypeSelect.control.valueChanges.pipe(
      startWith(this.locationTypeSelect.control.value),
      map((v) => v?.id),
      shareReplay({ refCount: false, bufferSize: 1 }),
    );
    const busAddressId = this.config.value?.busAddressId;
    const busAddressSelectValue =
      !!busAddressId && this.config.value?.locationType === 'bus' && this.config.value?.address
        ? ({
            value: busAddressId,
            displayLabel: this.config.value.address.formatted_address,
            meta: { busAddressId, address: this.config.value.address },
          } as SelectOption<string, BusAddress>)
        : null;
    this.busAddressSelect = new UntypedFormControl({ value: busAddressSelectValue, disabled: this.config.disabled });
    this.homeAddressSelect = new SmartInputAddress({
      label: 'Address',
      noOptionalLabel: true,
      value: this.config.value?.locationType === 'home' ? this.config.value?.address : undefined,
      disabled: this.config.disabled,
    });
    const exactSelectValue = this.config?.value?.exactLocation
      ? ({
          formatted_address: this.config?.value?.exactLocation?.label,
          geometry: {
            location: {
              lat: this.config?.value?.exactLocation.location.lat,
              lng: this.config?.value?.exactLocation.location.lng,
            },
          },
        } as ApiAddress)
      : this.config.value?.address;
    this.exactHomeAddressSelect = new SmartInputAddressMap({
      mapWidthPx: this.config.exactAddressSelect?.mapWidthPx || 573,
      mapHeightPx: this.config.exactAddressSelect?.mapHeightPx || 400,
      initialZoom: this.config.exactAddressSelect?.initialZoom || 18,
      centerMapOn: exactSelectValue,
      markers: this.getMarkersForAddress(this.config.value?.address, undefined),
      value: exactSelectValue,
      label: undefined,
      noGeocoder: true,
      disabled: this.config.disabled,
    });
    this.exactHomeAddressValue = new UntypedFormControl(this.config.value?.exactLocation);

    const formValidator = (control) => {
      if (this.config.required) {
        const v = control.value;
        const locType = v?.locationType?.id;
        if (locType === 'home') {
          if (!v?.homeAddress?.geometry) {
            return {
              required: 'Home address is required',
            };
          }
        } else if (locType === 'bus') {
          if (!v.busAddress?.value) {
            return {
              required: 'Bus address is required',
            };
          }
        } else if (!locType) {
          return {
            required: 'Please select location type',
          };
        }
      }
      return null;
    };

    this.formGroup = new UntypedFormGroup(
      {
        locationType: this.locationTypeSelect.control,
        busAddress: this.busAddressSelect,
        homeAddress: this.homeAddressSelect.control,
        exactHomeAddressLocation: this.exactHomeAddressValue,
      },
      [formValidator],
    );

    // closest stop input
    this.searchBusStopsNearAddress = new SmartInputAddress({
      label: 'Address',
      value: this.config.closestBusStops?.searchNearAddress,
      disabled: this.config.disabled,
      noOptionalLabel: true,
    });
  }

  private initFormControlListeners(): void {
    // load bus stops options if needed on location type change, make the corresponding controls required
    const typeChangeSub = this.locationTypeSelect.control.valueChanges
      .pipe(
        startWith(this.locationTypeSelect.control.value),
        map((v) => v?.id),
        distinctUntilChanged(),
      )
      .subscribe((val: AddressLocationType) => {
        this._hasChanges = true;
        const busAddRequired = this.config.required && val === 'bus';
        if (busAddRequired) {
          this.busAddressSelect.addValidators([Validators.required]);
        } else {
          this.busAddressSelect.removeValidators([Validators.required]);
        }
        this.setHomeSelectRequired(this.config.required && val === 'home');
        if (val === 'bus' && this.dataLoader && !this.isBusAddressesLoaded$.value) {
          this.loadBusAddressOptions();
          this.exactHomeAddressSelect.setValue(null);
        }
      });
    this.subscriptions.add(typeChangeSub);

    const homeAddrSub = this.homeAddressSelect.getValueChanges().subscribe((val) => {
      if (this.shouldLoadMapForHomeAddress) {
        this.isMapLoading$$.next(true);
        this.shoudLoadPinValueFlag = false;
        this.exactHomeAddressSelect.setValue(val);
        this.exactHomeAddressSelect.setMarkers(this.getMarkersForAddress(this.homeAddressSelect.getValue(), undefined));
        this.exactHomeAddressValue.setValue(null);
        this._hasChanges = true;
        setTimeout(() => {
          this.isMapLoading$$.next(false);
          this.shoudLoadPinValueFlag = true;
        }, 300);
      }
    });
    this.subscriptions.add(homeAddrSub);

    // load location info on select exact location with the map pin
    const pinChangedSub = this.exactHomeAddressSelect.getValueChanges().subscribe((val) => {
      if (this.shoudLoadPinValueFlag) {
        this._hasChanges = true;
        this.exactHomeAddressValue.setValue(null);
        this.loadExactLocationValue();
      }
    });
    this.subscriptions.add(pinChangedSub);
  }

  private async loadBusAddressOptions(): Promise<void> {
    this.isBusAddressesLoading$.next(true);
    try {
      const addresses = await this.dataLoader.getBusAddresses(this.districtId, this.districtProgramId);
      const options = addresses.map((addr) => {
        return {
          value: addr.busAddressId,
          displayLabel: addr.address.formatted_address,
          meta: addr,
        } as SelectOption<string, BusAddress>;
      });
      this.busAddressOptions$.next(options);
      this.isBusAddressesLoaded$.next(true);
    } catch (err) {
      console.error(err);
      this.busAddressLoadError$.next({
        text: 'Failed to load bus addresses',
        originalError: err,
      });
    } finally {
      this.isBusAddressesLoading$.next(false);
    }
  }

  private async loadExactLocationValue(): Promise<void> {
    const address = this.homeAddressSelect.getValue();
    const location = this.exactHomeAddressSelect.getValue()?.geometry?.location;
    this.isExactLocationValueLoadError$.next(null);
    if (address && location) {
      this.isExactLocationValueLoading$.next(true);
      try {
        const exactLocationValue = await this.dataLoader.getAddressByLocation(this.districtId, address, location);
        this.exactHomeAddressValue.setValue(exactLocationValue);
        this.shoudLoadPinValueFlag = false;
        this.exactHomeAddressSelect.setValue({
          formatted_address: exactLocationValue.label,
          geometry: { location: exactLocationValue.location },
        } as Address);
        setTimeout(() => (this.shoudLoadPinValueFlag = true), 200);
      } catch (originalError) {
        console.error(originalError);
        const error: WpError = {
          text: 'Failed to load data for this location',
          originalError,
        };
        this.isExactLocationValueLoadError$.next(error);
      } finally {
        this.isExactLocationValueLoading$.next(false);
      }
    } else {
      this.exactHomeAddressValue.setValue(undefined);
    }
  }

  private getMarkersForAddress(address: Address, exactLocationLatLng: { lat: number; lng: number }): MapMarker[] {
    if (address?.geometry) {
      if (
        exactLocationLatLng &&
        exactLocationLatLng.lat === address.geometry.location.lat &&
        exactLocationLatLng.lng === address.geometry.location.lng
      ) {
        return [];
      } else {
        return [
          {
            location: {
              lat: address.geometry.location.lat,
              lng: address.geometry.location.lng,
            },
            width: 42,
            height: 42,
            url: getAbsoluteIconPath(this.markerIconPath),
          },
        ];
      }
    } else {
      return [];
    }
  }

  private setHomeSelectRequired(required: boolean): void {
    if (required) {
      this.homeAddressSelect.control.addValidators(Validators.required);
    } else {
      this.homeAddressSelect.control.removeValidators(Validators.required);
    }
  }

  hasChanges(): boolean {
    return this._hasChanges;
  }
}

@Injectable({ providedIn: 'root' })
export class SmartAddressWithExactLocationDataLoaderService {
  constructor(private api: ApiService) {}

  public getDataLoader(): SmartAddressWithExactLocationDataLoader {
    return {
      getBusAddresses: (districtId: string, districtProgramId: string) => {
        const request: GetBusAddressesRequest = {
          districtId,
          districtProgramId,
        };
        return this.api
          .getBusAddresses(request)
          .pipe(map((r) => r.addresses))
          .toPromise();
      },
      getAddressByLocation: (districtId: string, address: Address, location: { lat: number; lng: number }) =>
        this.api.getAddressByLocation({ districtId, homeAddress: address, location }).toPromise(),
      getClosestBusStops: (districtId: string, districtProgramId: string, location: MapLocation, campusId: string) => {
        const request: GetBusAddressesRequest = {
          districtId,
          districtProgramId,
          location,
        };
        if (campusId) {
          request.isCalculateEnrollments = {
            campusIds: [campusId],
          };
        }
        const obs = this.api.getBusAddresses(request).pipe(map((r) => r.addresses));
        return firstValueFrom(obs);
      },
    };
  }
}
