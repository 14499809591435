import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  TrackByFunction,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import * as fromTypes from '../types';
import { Observable } from 'rxjs';
import { DateRangePickerService } from '../date-range-picker.service';
import { shareReplay } from 'rxjs/operators';
import { dateToYYYYMMDD } from '@rootTypes/utils/common/date-time-fns';

@Component({
  selector: 'wp-dp-month',
  templateUrl: './dp-month.component.html',
  styleUrls: ['./dp-month.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DpMonthComponent implements OnInit, OnDestroy {
  @Input() month!: fromTypes.RangePickerMonth;
  @Input() readonly!: boolean;
  @Input() borderSelectionStyle = false;

  public weekdays$!: Observable<fromTypes.RangePickerWeekday[]>;
  public days$!: Observable<fromTypes.RangePickerDay[]>;
  public placeholderDays$: Observable<fromTypes.RangePickerDay[]>;
  public isInitialized: boolean;

  public trackByFn: TrackByFunction<fromTypes.RangePickerDay> = (ind: number, item: fromTypes.RangePickerDay): any => {
    return item.label;
  };

  private timeoutHandle: any;

  constructor(
    private store: DateRangePickerService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.weekdays$ = this.store.weekdays$();
    this.days$ = this.store
      .daysForMonth$(dateToYYYYMMDD(this.month.moment))
      .pipe(shareReplay({ bufferSize: 1, refCount: false }));
    this.timeoutHandle = setTimeout(() => {
      this.isInitialized = true;
      this.cd.detectChanges();
    }, 400);
  }

  ngOnDestroy(): void {
    if (this.timeoutHandle) {
      try {
        clearTimeout(this.timeoutHandle);
      } catch (err) {
        // do nothing
      }
    }
  }

  public onMonthHeaderClick(): void {
    this.store.setViewType(fromTypes.ViewType.YEAR);
  }
}
