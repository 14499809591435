import { Injectable } from '@angular/core';
import { from, Observable, switchMap, throwError } from 'rxjs';
import { HttpApiService } from './http-api.service';
import { ConfirmationApiService } from '../../prompt-api';
import { NotificationApiService } from '../../notification-api/notification-api.service';
import { map } from 'rxjs/operators';
import { ResponseOrError } from '@rootTypes/entities/common/response-or-error';
import { isNotificationApiResponse } from '../../notification-api/is-notification-api-response';
import { notificationAPIHandledErrorType } from '../../notification-api/is-error-handled-by-notification-api';

@Injectable({
  providedIn: 'root',
})
export class BaseApiService {
  constructor(
    private httpApi: HttpApiService,
    private notificationAPI: NotificationApiService,
    private promptApi: ConfirmationApiService,
  ) {}

  /**
   * @param prefixEndpoint if omitted wpEnvironment.userRole is used
   */
  public get(apiName: string, prefixEndpoint?: string): Observable<any> {
    return from(this.httpApi.get(apiName, prefixEndpoint));
  }

  /**
   * @param prefixEndpoint if omitted wpEnvironment.userRole is used
   * @param overrideApiCall pass it to test API prompt responses
   */
  public post(
    apiName: string,
    request: any = {},
    prefixEndpoint?: string,
    overrideApiCall?: (request: any) => Promise<any>,
  ): Observable<any> {
    const apiRequestFn = overrideApiCall ?? ((r) => this.httpApi.post(apiName, r, prefixEndpoint));
    const responseOrError: Promise<ResponseOrError<any>> = this.httpApi
      .post(apiName, request, prefixEndpoint)
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
    return from(responseOrError).pipe(
      switchMap((res) => {
        // success case
        if (res.response) {
          return this.notificationAPI.handle(res.response).pipe(
            switchMap((r) => {
              return from(this.promptApi.handle(r, apiRequestFn));
            }),
          );
        } else if (isNotificationApiResponse(res.error)) {
          // error - notification API
          return this.notificationAPI.handle(res.error).pipe(
            switchMap((error) => {
              return throwError(() => ({
                ...error,
                type: notificationAPIHandledErrorType,
              }));
            }),
          );
        } else {
          // error - general
          return throwError(() => res.error);
        }
      }),
      map((response) => response.data),
    );
  }

  /**
   * @param prefixEndpoint if omitted wpEnvironment.userRole is used
   */
  public postBlob(apiName: string, request: any = {}, prefixEndpoint?: string): Observable<any> {
    return from(this.httpApi.postBlob(apiName, request, prefixEndpoint));
  }

  public postFormData(apiName: string, formData: FormData, prefixEndpoint?: string): Observable<any> {
    return from(this.httpApi.postFormData(apiName, formData, prefixEndpoint));
  }

  /**
   * @param prefixEndpoint if omitted wpEnvironment.userRole is used
   */
  public getBlob(apiName: string, prefixEndpoint?: string): Observable<any> {
    return from(this.httpApi.getBlob(apiName, prefixEndpoint));
  }
}
